import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, User } from '@ant-design/icons';
import {Card, notification} from 'antd';
import { QuestionCircleOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import { MdPeople, MdCategory, MdSchool, MdPlusOne, MdAdd, MdEditDocument, MdList } from 'react-icons/md';

const Menu = (props) => {

  useEffect(() => {
    localStorage.setItem('title', 'Bem-vindo a Plataforma');
    localStorage.setItem('type', '1');
  },[])

  const notity = () => {
    notification.error({
      message: 'Erro',
      description: 'Caro autor, o periodo de submissão de resumos ja expirou.\nPor favor acompanhe o processo revisao do(s) resumo(s) no butão Meus Resumos'
    })
  }


  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3  gap-4 p-2 sm:p-8">
        {/*<Link to="/resumo/novo">*/}
        {/*  <Card*/}
        {/*    bordered={false}*/}
        {/*    hoverable*/}
        {/*    cover={<MdEditDocument style={{ fontSize: '100px', color: '#00728a' }} />}*/}
        {/*  >*/}
        {/*    <div className="text-sm sm:text-lg text-center">Submeter Resumo</div>*/}
        {/*  </Card>*/}
        {/*</Link>*/}
        <Card
            onClick={notity}
            bordered={false}
            hoverable
            cover={<MdEditDocument style={{ fontSize: '100px', color: '#00728a' }} />}
        >
          <div className="text-sm sm:text-lg text-center">Submeter Resumo</div>
        </Card>
        <Link to="/resumo/lista">
          <Card
            bordered={false}
            hoverable
            cover={<MdList style={{ fontSize: '100px', color: '#00728a' }} />}
          >
            <div className="text-sm sm:text-lg text-center">Meus Resumos</div>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default Menu;